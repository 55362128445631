/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cart4: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 2.5A.5.5 0 01.5 2H2a.5.5 0 01.485.379L2.89 4H14.5a.5.5 0 01.485.621l-1.5 6A.5.5 0 0113 11H4a.5.5 0 01-.485-.379L1.61 3H.5a.5.5 0 01-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 100 2 1 1 0 000-2m-2 1a2 2 0 114 0 2 2 0 01-4 0m9-1a1 1 0 100 2 1 1 0 000-2m-2 1a2 2 0 114 0 2 2 0 01-4 0"/>',
    },
});
